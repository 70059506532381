@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* background-color: #0a192f; */
  color: #ccd6f6;
  background: #0A192F;
  background: -moz-linear-gradient(-45deg, #0A192F 0%, #1773CB 100%);
  background: -webkit-linear-gradient(-45deg, #0A192F 0%, #1773CB 100%);
  background: linear-gradient(135deg, #0A192F 0%, #1773CB 100%);
}
.icon {
  animation: updown 2s ease infinite;
}
@keyframes updown {
  0% {
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}